<template>
  <div class="Orderdetails_mini_item">
    <div class="Orderdetails_mini_titlle collapsed" data-bs-toggle="collapse" :href="`#mini-detail1-${_uid}`" role="button">
      <h4>
        <span>
          <img src="/assets/sabre-img/airplane1.png" alt="airplane1" v-if="isBack">
          <img src="/assets/sabre-img/airplane.png" alt="airplane" v-else>
        </span>
        <strong>{{$t("sabre.booking.order-detail-box.go-to-direct-where", {where: arrivalCityName})}}</strong>
        {{ $t("sabre.search-result.box.flight-duration", elapsedTime)}}
      </h4>
    </div>
    <div class="collapse" :id="`mini-detail1-${_uid}`">
      <div v-for="(segment, inx) in data.segmentExcerpts" :key="inx">
        <order-detail-mini-item :data="segment" :checkinBaggage="data.excerpt.checkinBaggageAllowance"/>
        <h3 v-if="inx !== data.excerpt.connections">
          <strong>{{$t("sabre.search-result.box.stop-in", {dest: arrivalCityName})}}</strong>
          {{$t("sabre.search-result.box.flight-hour-minute", elapsedTime)}}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import searchResultBoxMixin from '@/sabre/fo/searchResult/searchResultBox/searchResultBoxMixin';

export default {
  name: 'order-detail-mini-one-box',
  mixins: [searchResultBoxMixin],
  props: {
    isBack: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OrderDetailMiniItem: () => import('./orderDetailMiniItem'),
  },
};
</script>
